/* global config, moment, Promise */

'use strict';

angular.module('managerApp').controller('NotificationsController', (
        $scope, $mdDialog, toast, notifications, clients, procedureTools) => {

    $scope.config = config;

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            getNotifications();
        }
    };

    $scope.showAddEditPopup = () => {
        $mdDialog.show({
            templateUrl: 'app/notifications/item.html',
            clickOutsideToClose: false,
            locals: {
            },
            controller: ($scope, $mdDialog, notifications, clients) => {

                $scope.notification = {
                    procedureNum: '1904251314LEJE0',
                    subject: 'notification de test',
                    body: 'hello, ceci est un notification de test.'
                };

                $scope.onClientSelect = () => {
                    $scope.procedures = [];
                    if ($scope.client && $scope.client._id) {
                        clients.getProcedures($scope.client._id).then((procedures) => {
                            $scope.procedures = procedures;
                        }).catch((err) => {
                            console.error(err);
                        });
                    }
                };

                $scope.searchClient = (clientName) => {
                    return clients.get({
                        name: clientName
                    }).then((result) => {
                        return Promise.resolve(result.items);
                    }).catch((err) => {
                        return Promise.reject(err);
                    });
                };

                $scope.closeDialog = (validated = false, form = null) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        if ($scope.client) {
                            $scope.notification.ClientId = $scope.client._id;
                        }
                        $mdDialog.hide(notifications.send($scope.notification));
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le notification a bien été envoyé.', 'success');
                getNotifications();
            }
        }).catch((err) => {
            if (err)
                toast.show(err && err.notification ? err.notification : config.defaultErrorMsg, 'error', true);
        });
    };

    $scope.showDeleteConfirm = ($event, notification) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer ce notification ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                notificationId: notification._id
            },
            controller: ($scope, $mdDialog, toast, notifications, notificationId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        notifications.delete(notificationId).then(() => {
                            toast.show('Le notification a bien été supprimé.', 'success');
                            getBoxes();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression' +
                                    ' de ce notification.');
                        });
                    }//
                };
            }
        });
    };

    function getNotifications() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                let value = $scope.pagination.filters[i];
                if (i === 'date' || i === 'procedureDate') {
                    value = moment(value);
                    if (value.isValid()) {
                        params[i] = value.format(config.date.defaultFormat);
                    }
                } else {
                    if (i === 'procedureType' && value === 'all') {
                    } else {
                        params[i] = value;
                    }
                }

            }
        }
        notifications.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                item.Client.personType = 'personne-physique';
                item.clientName = procedureTools.getPersonName(item.Client);
                item.procedure = {
                    type: procedureTools.getProcedureName(item.Procedure.type),
                    num: item.Procedure.number
                };
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    }

    getNotifications();

});
